// extracted by mini-css-extract-plugin
export var animation = "hero-module--animation--tRxFO";
export var backLink = "hero-module--backLink--OjRs6";
export var desktopHeader = "hero-module--desktopHeader--ekiMK";
export var digitalWrapper = "hero-module--digitalWrapper--71RDc";
export var goDown = "hero-module--goDown--PUmNx";
export var heading = "hero-module--heading--jY8Tu";
export var hero = "hero-module--hero--sOQXB";
export var heroText = "hero-module--heroText--7oN1H";
export var mainHeadingOne = "hero-module--mainHeadingOne--XVS7Y";
export var mainHeadingTwo = "hero-module--mainHeadingTwo--KQAbb";
export var mobileHeader = "hero-module--mobileHeader--JjC5O";
export var text = "hero-module--text--tR1D-";
export var upFloat = "hero-module--upFloat--Fbmpk";
export var visionWrapper = "hero-module--visionWrapper--mC6nn";