import PropTypes from 'prop-types';
import {useRef} from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import headerTypes from '../../../data/headerTypes';
import ArrowLeft from '../../../assets/images/arrow_left_white.svg';
import {HeaderContext} from '../header/header';
import setStyles from '../../../utils/setStyles';
import * as styles from './hero.module.css';

const Hero = ({
    backLinkPath,
    backLinkText,
    description,
    headerType,
    title,
}) => {
    const config = headerTypes[headerType];
    const heroRef = useRef(null);
    const sendDataToGTM = useGTMDispatch();

    const splitTitle = title.split(' ').map((item, index) => (
        <div
            key={`splittitle-${index}`}
            className={clsx(
                index === 0 ? styles.digitalWrapper : styles.visionWrapper,
                styles.animation
            )}
        >
            <h1
                className={clsx(
                    index === 0 ? styles.mainHeadingOne : styles.mainHeadingTwo,
                    styles.animation
                )}
            >
                {item}
            </h1>
        </div>
    ));

    return (
        <HeaderContext.Consumer>
            {headerRef => {
                if (headerRef && headerRef.current && heroRef && heroRef.current) {
                    setStyles(headerRef.current, {
                        '--burger-stroke': config.burger,
                        '--menu-underline-color': config.buttonBackground,
                    });
                    setStyles(heroRef.current, {
                        '--heading-gradient-start': config.gradientStart,
                        '--heading-gradient-end': config.gradientEnd,
                        '--hero-text-color': config.textColor,
                    });
                }

                return (
                    <div ref={heroRef} className={styles.hero}>
                        <div className={styles.text}>
                            {backLinkPath && (
                                <div className={styles.backLink}>
                                    <Link
                                        to={backLinkPath}
                                        onClick={() => {
                                            sendDataToGTM({
                                                event: 'linkClick',
                                                linkText: backLinkText,
                                                targetURL: backLinkPath,
                                            });
                                        }}
                                    >
                                        <span>
                                            <ArrowLeft/>
                                        </span>
                                        {backLinkText}
                                    </Link>
                                </div>
                            )}
                            <div className={styles.mobileHeader}>
                                {splitTitle}
                            </div>
                            <div
                                className={clsx(
                                    styles.digitalWrapper,
                                    styles.animation,
                                    styles.desktopHeader
                                )}
                            >
                                <h1 className={clsx(styles.mainHeadingOne, styles.animation)}>
                                    {title}
                                </h1>
                            </div>
                            <h4
                                data-aos="fade-up"
                                data-aos-delay="600"
                                data-aos-duration="1000"
                                data-aos-easing="ease-out"
                                className={styles.heroText}
                            >
                                {description}
                            </h4>
                        </div>
                    </div>
                );
            }}
        </HeaderContext.Consumer>
    );
};

Hero.propTypes = {
    backLinkPath: PropTypes.bool,
    backLinkText: PropTypes.string,
    description: PropTypes.any,
    headerType: PropTypes.string,
    title: PropTypes.string,
};

export default Hero;
