/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import TransitionLink, {TransitionState, useTriggerTransition} from 'gatsby-plugin-transition-link';
import clsx from 'clsx';
import {useContext, useEffect, useRef} from 'react';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Layout, Header} from '../../components/layout';
import pageTypes from '../../utils/enums/pageType';
import Hero from '../../components/layout/hero/hero';
import caseStudies from '../../data/caseStudies';
import {isExiting} from '../../utils/animations';
import hpToCaseTransition from '../../utils/animations/hpToCase';
import {MainStateContext} from '../../components/layout/stateWrapper';
import * as styles from './index.module.css';

const CaseStudy = ({
    slug,
    title,
    linkTo,
    exiting,
    imageSrcAlt,
    cases: {
        tags,
        imageAlt,
        description,
    },
    context,
    pageRef,
}) => {
    const sendDataToGTM = useGTMDispatch();

    const handleImageClick = () => {
        sendDataToGTM({
            event: 'imageClick',
            imageAlt,
            type: 'case',
            targetURL: linkTo,
        });
    };

    return (
        <TransitionLink
            to={linkTo}
            exit={{
                state: {
                    caseName: slug,
                },
            }}
            entry={{
                state: {
                    isTransition: true,
                },
            }}
            trigger={async pages => {
                context.setState(previous => ({
                    ...previous,
                    firstLoad: true,
                }));
                await hpToCaseTransition(pages, pageRef);
            }}
            className={clsx(styles.caseContainer, exiting && styles.exiting)}
        >
            <span className={styles.image}>
                <img
                    onClick={handleImageClick}
                    src={imageSrcAlt}
                    alt={imageAlt}
                    data-animation-image="case"
                    data-animation-case={slug}
                />
            </span>
            <span className={styles.textWrapper}>
                {tags.map((tag, index) => (
                    <span key={`tag-${index}`} className={styles.tags}>
                        {index > 0 && (<> - </>)}
                        {tag}
                    </span>
                ))}
                <span className={styles.title}>
                    {title}
                </span>
                <p>{description}</p>
            </span>
        </TransitionLink>
    );
};

const IndexPage = () => {
    const context = useContext(MainStateContext) || {firstLoad: true};
    const pageRef = useRef(null);
    const entryTransition = useTriggerTransition({
        to: '/cases',
        entry: {
            length: 0.5,
            state: {
                pageType: pageTypes.cases,
                nextPageType: pageTypes.cases,
                isTransition: true,
            },
        },
    });

    useEffect(() => {
        entryTransition({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout hideFooterButton={false} innerRef={pageRef}>
            <Helmet>
                <title>Case studies | Digital Vision CZ</title>
                <meta
                    name="description"
                    content="Pomáháme firmám využít jejich potenciál na internetu a v digitálu. Podívejte se, jaké problémy jsme už vyřešili."
                />
            </Helmet>
            <Header pageType={pageTypes.cases}>
                <Hero
                    headerType='black'
                    title='Naše projekty'
                    description='Pomáháme firmám využít jejich potenciál na internetu a v digitálu. Podívejte se, jaké problémy jsme už vyřešili.'
                />
            </Header>
            <TransitionState>
                {({transitionStatus}) => {
                    const exiting = isExiting(transitionStatus);

                    return (
                        <div className={clsx(styles.cases, exiting && styles.exiting)} >
                            {caseStudies.map((caseStudy, index) => (
                                <CaseStudy
                                    key={`casesStudy=${index}`}
                                    exiting={exiting}
                                    context={context}
                                    pageRef={pageRef}
                                    {...caseStudy}
                                />
                            ))}
                        </div>
                    );
                }}
            </TransitionState>
        </Layout>
    );
};

CaseStudy.propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    linkTo: PropTypes.string,
    cases: PropTypes.object,
    tags: PropTypes.array,
    imageSrcAlt: PropTypes.string,
    imageAlt: PropTypes.string,
    description: PropTypes.string,
    exiting: PropTypes.bool,
    context: PropTypes.any,
    pageRef: PropTypes.any,
};

export default IndexPage;
