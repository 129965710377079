import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {entryAnimation} from './case';

gsap.registerPlugin(ScrollToPlugin);

const transition = async (pages, pageRef) => {
    const {body} = document;
    const exit = await pages.exit;
    const entry = await pages.entry;

    const offset = window.scrollY;
    const caseHeroImageHP = exit.node.querySelector(`[data-animation-image="case"][data-animation-case="${exit.state.caseName}"]`);
    const caseHeroImage = entry.node.querySelector('[data-animation-image="case"]');
    const caseHeroTitle = entry.node.querySelector('[data-animation-type="case-title"]');

    const clonedHero = caseHeroImageHP.cloneNode(true);

    const caseHeroImageHPCoords = caseHeroImageHP.getBoundingClientRect();
    const caseHeroImageCoords = caseHeroImage.getBoundingClientRect();

    gsap.set(clonedHero, {
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 100,
        borderRadius: '0',
        width: caseHeroImageHPCoords.width,
        height: caseHeroImageHPCoords.height,
        top: Math.abs(caseHeroImageHPCoords.top + offset),
        left: caseHeroImageHPCoords.left,
    });

    body.appendChild(clonedHero);

    const newHeight = 0.5628 * caseHeroImageCoords.width;
    let newTop = caseHeroImageCoords.top + offset;

    if (newTop > ((window.innerHeight * 0.6) - 150)) {
        newTop -= (newHeight / 2);
    }

    gsap.fromTo(pageRef.current, {opacity: 1}, {opacity: 0, duration: 1});
    gsap.fromTo(clonedHero, {
        width: caseHeroImageHPCoords.width,
        height: caseHeroImageHPCoords.height,
        top: caseHeroImageHPCoords.top,
        left: caseHeroImageHPCoords.left,
        borderRadius: '0',
    }, {
        width: caseHeroImageCoords.width,
        height: newHeight,
        top: newTop,
        left: caseHeroImageCoords.left,
        borderRadius: '10px',
        duration: 1,
        ease: 'power1.inOut',
        onStart: () => {
            gsap.set(window, {
                scrollTo: 0,
                ease: 'power3.out',
            });
        },
        onComplete: () => {
            entryAnimation({
                pageRef: entry.node,
                caseHeroImage,
                caseHeroTitle,
                clonedHero,
                isTransition: true,
            });
        },
    });
};

export default transition;
